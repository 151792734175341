import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BiDownArrow } from 'react-icons/bi';
import { carer_tabs, parent_tabs, user } from '../../constants/AppData';
import { ORANGE, LIGHTBLUE } from '../../constants/Colors';
import { snakeCase } from 'lodash';
import InputModal from '../InputModal';
import { scrollToAction, selPathAction } from '../../actions';

const Ul = styled.ul`
	list-style: none;
	display: flex;
	flex-flow: row nowrap;
	margin: 0px;
	padding: 0px;
	cursor: pointer;
	font-size: 17px;
	font-weight: bold;
	color: ${LIGHTBLUE};
	justify-content: flex-end;

	li {
		padding: 10px;
		margin-right: 7px;
		align-content: center;
	}
	li#out {
		color: white;
		border: 2px solid black;
		border-radius: 10px 10rem 10px 10px;
		background-color: rgb(231, 114, 37);
	}
	li.Active {
		color: #ec962f;
		transition: 0.5s;
	}

	li:hover {
		color: ${ORANGE};
		transition: 0.5s;
	}
	li ul li.active-list {
		color: yellow;
		text-decoration: bold;

		padding: 8px 22px;

		transition: 0.5s;
	}
	li ul {
		display: none;
		margin-left: 0px;
		border-radius: 10px 40px 10px 10px;

		background: ${LIGHTBLUE};
		color: white;
		opacity: 0.8;
	}
	li ul {
		color: white;
	}
	li:hover ul {
		display: block;
		position: absolute;
		z-index: 22;
	}
	li:hover ul li {
		display: block;
	}
	@media (max-width: 1199px) {
		li {
			padding: 7px;
			margin-right: 4px;
			align-content: center;
		}
	}
	@media (max-width: 1007px) {
		flex-flow: column nowrap;
		justify-content: flex-start;

		background-color: ${LIGHTBLUE};
		opacity: 0.9;
		z-index: 22; /* Specify a stack order in case you're using a different order for other elements */
		position: fixed;
		transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
		top: 0;
		right: 0;
		height: 160vh;
		width: 300px;
		padding-top: 3.5rem;
		transition: transform 0.3s ease-in-out;

		li {
			color: #fff;
		}
		li ul {
			position: static;
			background-color: rbga(0, 0, 0, 0);
			display: block;
		}
	}
`;

const RightNav = ({ open }) => {
	const history = useHistory();
	const { current } = useSelector(({ cla_authReducer }) => cla_authReducer);
	const { currently_in, region, town } = useSelector(
		({ cla_menuReducer: { loc } }) => loc || {},
	);
	const isCook = useSelector(({ cla_datastore }) =>
		cla_datastore.user?.positions.includes('cook'),
	);
	const dispatch = useDispatch();
	const location = history.location;
	const path = location.pathname;
	const subPath = `${path.substring(path.lastIndexOf('/') + 1, path.length)}${
		location.search ? location.search : ''
	}`;
	console.log('path location ', location);
	const param = path.includes('my_account') ? subPath : current?.uid;
	const [allTabs, setallTabs] = useState();

	const [modalShow, setModalShow] = useState(false);

	const [payload, setPayload] = useState();
	const signOutCallBack = async () => {
		console.log('emailed we out');
		const { updateFireAction } = await import('../../actions/firestore');
		dispatch(updateFireAction(user));
		setModalShow(false);
		history.replace('/');
	};
	useEffect(() => {
		const isParent = current?.parent;
		let all = isParent ? parent_tabs : carer_tabs;
		if (!isParent && !isCook) {
			all = all.filter((val) => val.title !== 'Orders');
		}
		console.log('all ', all.length);
		setallTabs(all);
	}, [isCook, path, current?.parent]);
	return (
		<>
			<Ul open={open}>
				{!current?.isEmpty && (
					<li className={'Acc'}>
						<div
							onClick={() =>
								history.push(
									`/my_account/${
										current.parent ? 'parent' : 'caregiver'
									}/overview/user/${param}`,
								)
							}
						>
							<span>My account</span>
							<BiDownArrow />
						</div>
						<ul>
							{allTabs &&
								allTabs.map((item) => (
									<li
										key={item.title}
										onClick={() => {
											dispatch(selPathAction(item.path));

											history.push(`${item.path}/${param}`);
										}}
										className={
											path.includes(item.path) ? 'Active' : 'NotActive'
										}
									>
										{item.title}
									</li>
								))}
						</ul>
					</li>
				)}
				<li
					onClick={() => {
						dispatch(scrollToAction(true));
						history.push('/');
					}}
					className={'NotActive'}
				>
					Our Services
				</li>
				<li
					onClick={() => history.push('/aboutus')}
					className={path.includes('aboutus') ? 'Active' : 'NotActive'}
				>
					About Us
				</li>
				<li
					onClick={() =>
						history.push(
							`/find_job/params?currently_in=${snakeCase(
								currently_in,
							)}&region=${snakeCase(region)}&town=${snakeCase(
								town,
							)}&isScroll=false&positions=any_position`,
						)
					}
					className={path.includes('find_job') ? 'Active' : 'NotActive'}
				>
					For caregivers
				</li>

				<li
					onClick={() =>
						history.push(
							`/find_childcarer/params?currently_in=${snakeCase(
								currently_in,
							)}&region=${snakeCase(region)}&town=${snakeCase(
								town,
							)}&isScroll=false&positions=any_position`,
						)
					}
					className={path.includes('find_childcarer') ? 'Active' : 'NotActive'}
				>
					For parents
				</li>

				{/* <li
          onClick={() => history.push('/caregiver_courses')}
          className={
            path.includes('childcare_courses') ? 'Active' : 'NotActive'
          }
        >
          Courses
        </li> */}
				<li
					onClick={() => history.push('/advice_hub')}
					className={path.includes('advice_hub') ? 'Active' : 'NotActive'}
				>
					Advice Hub
				</li>

				<li
					onClick={() => {
						if (!current?.isEmpty) {
							setPayload({
								title: 'Sign Out',
								body: 'Do you want to Sign out?',
								hint: 'Enter your Email',
								type: 'Email',
								action: 'Sign Out',
							});
							setModalShow(true);
						} else {
							const url =
								path.includes('login') || path.includes('register')
									? window.location.href
											.replace(path, '')
											.replace(/[/]/g, '%2F')
											.replace(/[:]/g, '%3A')
									: window.location.href
											.replace(/[/]/g, '%2F')
											.replace(/[:]/g, '%3A');
							console.log('url ', url);
							history.push(`/login/params?return=${url}`);
						}
					}}
					className={path.includes('login') ? 'Active' : 'NotActive'}
				>
					{!current?.isEmpty ? 'Logout' : 'Login'}
				</li>
			</Ul>
			{payload && (
				<InputModal
					{...{ payload }}
					show={modalShow}
					onActionFun={() => {
						console.log('emailed we payload.action ' + payload.action);

						setPayload({ ...payload, isLogging: true });
						if (payload.action === 'Done') return;
						console.log('emailed we out');
						import('../../actions/Auth').then(({ signout }) => {
							dispatch(signout(signOutCallBack));
						});
					}}
					onHide={() => setModalShow(false)}
				/>
			)}
		</>
	);
};

export default memo(RightNav);
